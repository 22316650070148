// src/AdminPage.tsx
import React, { useState, useEffect } from "react";
import {
  Container,
  TextField,
  Button,
  Grid,
  Paper,
  Typography,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { auth } from "../firebase";
import { format, toZonedTime } from "date-fns-tz";
// Utility function to convert Firestore timestamp to JavaScript Date
const firestoreTimestampToDate = (timestamp: {
  _seconds: number;
  _nanoseconds: number;
}): Date => {
  return new Date(timestamp._seconds * 1000 + timestamp._nanoseconds / 1000000);
};

const formatFirebaseTimestamp = (
  timestamp: { _seconds: number; _nanoseconds: number },
  timeZone: string
): string => {
  const date = firestoreTimestampToDate(timestamp);
  console.log(timestamp, date);
  const zonedDate = toZonedTime(date, timeZone);
  return format(zonedDate, "MM/dd/yyyy hh:mm a", { timeZone });
};

const AdminPage = () => {
  const [event, setEvent] = useState<Omit<Event, "id">>({
    title: "",
    description: "",
    address: "",
    contactMessage: "",
    imageUrl: "",
    locationName: "",
    cityName: "",
    startDate: new Date().toISOString().substring(0, 16),
    endDate: "",
    timeRange: "",
  });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [events, setEvents] = useState<Event[]>([]);
  const [deleteId, setDeleteId] = useState("");

  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = async () => {
    setLoading(true);
    try {
      const user = auth.currentUser;
      if (user) {
        const idToken = await user.getIdToken();
        const res = await axios.get<Event[]>(
          "https://us-central1-vicinity-e7a18.cloudfunctions.net/fetchEvents",
          {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          }
        );
        console.log("bjj1", res.data);
        const final = res.data.map((event) => {
          if (event.startDate) {
            event.startDate = formatFirebaseTimestamp(
              event.startDate as any,
              Intl.DateTimeFormat().resolvedOptions().timeZone
            );
          }
          if (event.endDate) {
            event.endDate = formatFirebaseTimestamp(
              event.endDate as any,
              Intl.DateTimeFormat().resolvedOptions().timeZone
            );
          }
          return event;
        });
        setEvents(final);
      }
    } catch (error) {
      console.error("Error fetching events", error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEvent({ ...event, [name]: value });
  };

  const handleAddEvent = async () => {
    setLoading(true);
    try {
      const user = auth.currentUser;
      if (user) {
        const idToken = await user.getIdToken();
        const res = await axios.post<{ id: string }>(
          "https://us-central1-vicinity-e7a18.cloudfunctions.net/addEvent",
          {
            ...event,
            startDate: new Date(event.startDate).toISOString(),
            endDate: event.endDate
              ? new Date(event.endDate).toISOString()
              : null,
          },
          {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          }
        );
        setMessage(`Event added with ID: ${res.data.id}`);
        fetchEvents(); // Refresh events list
      }
    } catch (error) {
      setMessage("Error adding event");
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteEvent = async (eventId: string) => {
    setLoading(true);
    try {
      const user = auth.currentUser;
      if (user) {
        const idToken = await user.getIdToken();
        await axios.post(
          "https://us-central1-vicinity-e7a18.cloudfunctions.net/deleteEvent",
          { id: deleteId },
          {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          }
        );
        setMessage("Event deleted successfully");
        setDeleteId("");
        fetchEvents(); // Refresh events list
      }
    } catch (error) {
      setMessage("Error deleting event");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <Paper style={{ padding: "16px", marginBottom: "16px" }}>
        <Typography variant="h4" gutterBottom>
          Add Event
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              name="title"
              label="Title"
              fullWidth
              value={event.title}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="description"
              label="Description"
              fullWidth
              value={event.description}
              onChange={handleChange}
              multiline
              rows={4} // Adjust the number of rows as needed
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="address"
              label="Address"
              fullWidth
              value={event.address}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="contactMessage"
              label="Contact Message"
              fullWidth
              value={event.contactMessage}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="imageUrl"
              label="Image URL"
              fullWidth
              value={event.imageUrl}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="locationName"
              label="Location Name"
              fullWidth
              value={event.locationName}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="cityName"
              label="City Name"
              fullWidth
              value={event.cityName}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography>{`Dates are using timezone: ${
              Intl.DateTimeFormat().resolvedOptions().timeZone
            }`}</Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="startDate"
              label="Start Date"
              type="datetime-local"
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              value={event.startDate}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="endDate"
              label="End Date"
              type="datetime-local"
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              value={event.endDate}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="timeRange"
              label="Time Range"
              fullWidth
              value={event.timeRange}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddEvent}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : "Add Event"}
            </Button>
          </Grid>
        </Grid>
        {message && (
          <Typography
            variant="h6"
            color="textSecondary"
            style={{ marginTop: "16px" }}
          >
            {message}
          </Typography>
        )}
      </Paper>

      <Paper style={{ padding: "16px", marginBottom: "16px" }}>
        <Typography variant="h4" gutterBottom>
          Delete Event
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              name="deleteId"
              label="Event ID to Delete"
              fullWidth
              value={deleteId}
              onChange={(e) => setDeleteId(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => handleDeleteEvent(deleteId)}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : "Delete Event"}
            </Button>
          </Grid>
        </Grid>
      </Paper>

      <Paper style={{ padding: "16px" }}>
        <Typography variant="h4" gutterBottom>
          Events List
        </Typography>
        {loading ? (
          <CircularProgress />
        ) : (
          events.map((event) => (
            <Accordion key={event.id}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>{event.title}</Typography>
                <Typography style={{ marginLeft: "auto" }}>
                  {`${event.startDate ? event.startDate : "N/A"} - ${
                    Intl.DateTimeFormat().resolvedOptions().timeZone
                  }`}
                </Typography>
                <Typography style={{ marginLeft: "auto" }}>
                  {event.id}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography>
                      <strong>Description:</strong> {event.description}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                      <strong>Address:</strong> {event.address}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                      <strong>Contact Message:</strong> {event.contactMessage}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                      <strong>Image URL:</strong> {event.imageUrl}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                      <strong>Location Name:</strong> {event.locationName}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                      <strong>City Name:</strong> {event.cityName}
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography>
                      <strong>Start Date:</strong>{" "}
                      {event.startDate ? event.startDate : "N/A"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                      <strong>End Date:</strong>{" "}
                      {event.endDate ? event.endDate : "N/A"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                      <strong>Time Range:</strong> {event.timeRange}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        setDeleteId(event.id);
                        handleDeleteEvent(event.id);
                      }}
                      startIcon={<DeleteIcon />}
                    >
                      Delete Event
                    </Button>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          ))
        )}
      </Paper>
    </Container>
  );
};

export default AdminPage;

export interface Event {
  id: string;
  title: string;
  description: string;
  address: string;
  contactMessage: string;
  imageUrl: string;
  locationName: string;
  cityName: string;
  startDate: string; // ISO date string
  endDate: string | null; // ISO date string
  timeRange: string;
}

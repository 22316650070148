import React, { useEffect, useState } from "react";
import { IconButton, Typography, Grid } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import XIcon from "@mui/icons-material/X";
import EmailIcon from "@mui/icons-material/Email";
import SmsIcon from "@mui/icons-material/Sms";
import ShareIcon from "@mui/icons-material/Share";

export const title = "Find LGBTQ+ Friends in Your Area with the Vicinity App\n";

export const body =
  "I think you will like this app a lot!\n\nVicinity is a map-based app to discover new LGBTQ+ friends around you 🌈\n\nIt's for friends... no swiping, dating, or hooking up.\nVicinity will launch in STL this summer!\n\nJoin me as a founding member at";

const url = encodeURIComponent("www.vicinityapp.io");
export const encodedTitle = encodeURIComponent(title);
export const encodedBody = encodeURIComponent(body);

function ShareButtons() {
  const [smallScreen, setSmallScreen] = useState(false);

  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title,
          text: body,
          url,
        });
      } catch (error) {
        console.error("Something went wrong sharing the blog", error);
      }
    } else {
      console.log("Web Share API is not supported in your browser.");
    }
  };

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 600px)");
    setSmallScreen(mediaQuery.matches);

    const handler = (event: MediaQueryListEvent) => {
      setSmallScreen(event.matches);
    };

    mediaQuery.addEventListener("change", handler);
    return () => mediaQuery.removeEventListener("change", handler);
  }, []);

  return (
    <>
      <Typography
        variant="h6"
        paddingTop="40px"
        paddingBottom="10px"
        style={{ margin: "0 auto" }}
        textAlign={"center"}
      >
        Spread the word!
      </Typography>
      <Grid
        item
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
        xs={12}
      >
        {isMobile && (
          <Grid item>
            <IconButton
              style={{
                color: "white",
                backgroundColor: "#f6be42 ",
                borderRadius: 10,
                width: "40px",
                height: "40px",
              }}
              href={`sms:?body=${encodedBody} ${url} `}
            >
              <SmsIcon />
            </IconButton>
          </Grid>
        )}

        <Grid item>
          <IconButton
            style={{
              color: "white",
              backgroundColor: "#3b5998",
              borderRadius: 10,
              width: smallScreen ? "40px" : "100px",
              height: "40px",
            }}
            onClick={(e) => {
              e.preventDefault();
              if (navigator.share) {
                navigator
                  .share({
                    title: title,
                    text: body,
                    url: "https://www.vicinityapp.io",
                  })
                  .catch((error) => console.log("Error sharing", error));
              } else {
                window.open(
                  "https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.vicinityapp.io%2F&amp;",
                  "shareWindow",
                  "width=800,height=600"
                );
              }
            }}
          >
            <FacebookIcon />
            {!smallScreen && (
              <Typography paddingLeft="5px" paddingRight="5px">
                Share
              </Typography>
            )}
          </IconButton>
        </Grid>

        <Grid item>
          <IconButton
            style={{
              color: "white",
              backgroundColor: "black",
              borderRadius: 10,
              width: smallScreen ? "40px" : "100px",
              height: "40px",
            }}
            href={`https://twitter.com/intent/tweet?url=${url}&text=${encodedBody}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <XIcon />
            {!smallScreen && (
              <Typography paddingLeft="5px" paddingRight="5px">
                Tweet
              </Typography>
            )}
          </IconButton>
        </Grid>

        <Grid item>
          <IconButton
            style={{
              color: "white",
              backgroundColor: "gray",
              borderRadius: 10,
              width: smallScreen ? "40px" : "100px",
              height: "40px",
            }}
            href={`mailto:?subject=${encodedTitle}&body=${encodedBody} ${url}`}
          >
            <EmailIcon />
            {!smallScreen && (
              <Typography paddingLeft="5px" paddingRight="5px">
                Email
              </Typography>
            )}
          </IconButton>
        </Grid>
        {isMobile && (
          <Grid item>
            <IconButton
              style={{
                color: "white",
                backgroundColor: "#a1cc54",
                borderRadius: 10,
                width: "40px",
                height: "40px",
              }}
              onClick={handleShare}
            >
              <ShareIcon />
            </IconButton>
          </Grid>
        )}
      </Grid>
    </>
  );
}

export default ShareButtons;

import React from "react";

export default function DisclaimerDialogContent() {
  return (
    <section>
      <h3>Privacy Policy </h3>
      <h6>Last Updated: March 1, 2024 </h6>
      <p>
        Thank you for visiting the Privacy Policy of Vicinity, LLC. This Privacy
        Policy explains how Vicinity, LLC (collectively, “Vicinity, LLC”, “we”,
        “us”, or “our”) collects, uses, and shares information about you (“you”,
        “yours” or “user”) when you access or use our websites (“Services”).{" "}
      </p>
      <p>
        Users are responsible for any third-party data they provide or share
        through the Services and confirm that they have the third-party's
        consent to provide such data to us.{" "}
      </p>
      <h3>Information We Collect </h3>
      <h4>Account Information:</h4> if you create an account on the Services, we
      collect the information you provide to us related to the account, such as
      first and last name, username, password, and email address.
      <h3>How We Use Your Information </h3>
      <p>
        We use information we collect about you to provide, maintain, and
        improve our Services and other interactions we have with you. For
        example, we use the information collected to:
      </p>
      <ul>
        <li> Facilitate and improve our online experience; </li>
        <li>
          {" "}
          Provide and deliver products and services, perform authentication,
          process transactions and returns, and send you related information,
          including confirmations, receipts, invoices, customer experience
          surveys, and product or Services-related notices;{" "}
        </li>
        <li> Process and deliver promotions; </li>
        <li>
          {" "}
          Respond to your comments and questions and provide customer service;{" "}
        </li>
        <li>
          {" "}
          If you have indicated to us that you wish to receive notifications or
          promotional messages;{" "}
        </li>
        <li>
          {" "}
          Detect, investigate and prevent fraudulent transactions and other
          illegal activities and protect our rights and property and others;{" "}
        </li>
        <li>
          {" "}
          Comply with our legal and financial obligations; Monitor and analyze
          trends, usage, and activities;{" "}
        </li>
        <li>
          {" "}
          Provide and allow our partners to provide advertising and marketing
          targeted toward your interests.{" "}
        </li>
      </ul>
      <h3>How We May Share Information </h3>
      <p>
        We may share your Personal Information in the following situations:{" "}
      </p>
      <ul>
        <li>
          {" "}
          Third Party Services Providers. We may share data with service
          providers, vendors, contractors, or agents who complete transactions
          or perform services on our behalf, such as those that assist us with
          our business and internal operations like shipping and delivery,
          payment processing, fraud prevention, customer service, gift cards,
          experiences, personalization, marketing, and advertising;{" "}
        </li>
        <li>
          {" "}
          Change in Business. We may share data in connection with a corporate
          business transaction, such as a merger or acquisition of all or a
          portion of our business to another company, joint venture, corporate
          reorganization, insolvency or bankruptcy, financing or sale of company
          assets;{" "}
        </li>
        <li>
          {" "}
          To Comply with Law. We may share data to facilitate legal process from
          lawful requests by public authorities, including to meet national
          security or law enforcement demands as permitted by law.{" "}
        </li>
        <li>
          {" "}
          With Your Consent. We may share data with third parties when we have
          your consent.{" "}
        </li>
        <li>
          {" "}
          With Advertising and Analytics Partners. See the section entitled
          “Advertising and Analytics” below.{" "}
        </li>
      </ul>
      <h3>Google Analytics </h3>
      <p>
        We use Google Analytics, an analytics service provided by Google LLC. We
        use this service to help analyze how users use the Service, with a view
        to analyzing usage across devices and offering improvements for all
        users.{" "}
      </p>
      <h3>Data Security </h3>
      <p>
        We implement commercially reasonable security measures designed to
        protect your information. Despite our best efforts, however, no security
        measures are completely impenetrable.{" "}
      </p>
      <h3>Data Retention </h3>
      <p>
        We store the information we collect about you for as long as necessary
        for the purpose(s) for which we collected it or for other legitimate
        business purposes, including to meet our legal, regulatory, or other
        compliance obligations.{" "}
      </p>
      <h3>Age Limitations </h3>
      <p>
        Our Service is intended for adults ages 18 years and above. We do not
        knowingly collect personally identifiable information from children. If
        you are a parent or legal guardian and think your child under 13 has
        given us information, please email or write to us at the address listed
        at the end of this Privacy Policy. Please mark your inquiries “COPPA
        Information Request.”{" "}
      </p>
      <h3>Changes to this Privacy Policy </h3>
      <p>
        Vicinity, LLC may change this Privacy Policy from time to time. We
        encourage you to visit this page to stay informed. If the changes are
        material, we may provide you additional notice to your email address or
        through our Services. Your continued use of the Services indicates your
        acceptance of the modified Privacy Policy.{" "}
      </p>
      <h3>Storage of Information in the United States </h3>
      <p>
        Information we maintain may be stored both within and outside of the
        United States. If you live outside of the United States, you understand
        and agree that we may transfer your information to the United States,
        and that U.S. laws may not afford the same level of protection as those
        in your country.{" "}
      </p>
      <h3>Contact Us </h3>
      <p>
        If you have questions, comments, or concerns about this Privacy Policy,
        you may contact us at: admin@vicinityapp.io{" "}
      </p>
    </section>
  );
}

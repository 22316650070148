import { initializeApp, getApps, getApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAHJj_T0FjhA25JuaCK8WC3a7t68c60zWA",
  authDomain: "vicinity-e7a18.firebaseapp.com",
  projectId: "vicinity-e7a18",
  storageBucket: "vicinity-e7a18.appspot.com",
  messagingSenderId: "356366498919",
  appId: "1:356366498919:web:960eec3525be2229b7e8c2",
  measurementId: "G-096M34FJDT",
};

const app = !getApps().length ? initializeApp(firebaseConfig) : getApp();
const auth = getAuth(app);

export { auth };

// src/SignIn.tsx
import React, { useState } from "react";
import {
  PhoneAuthProvider,
  RecaptchaVerifier,
  signInWithCredential,
  signInWithPhoneNumber,
} from "firebase/auth";
import {
  Button,
  TextField,
  Container,
  Typography,
  InputAdornment,
} from "@mui/material";
import { auth } from "./firebase";

const SignIn = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [verificationId, setVerificationId] = useState<string | null>(null);

  const setupRecaptcha = () => {};

  const handleSendOtp = async () => {
    if (!(window as any).recaptchaVerifier) {
      (window as any).recaptchaVerifier = new RecaptchaVerifier(
        auth,
        "recaptcha-container",
        {
          size: "invisible",
          callback: () => {
            // reCAPTCHA solved - will proceed with submit function
          },
          "expired-callback": () => {
            // Reset reCAPTCHA?
          },
        }
      );
    }
    const appVerifier = (window as any).recaptchaVerifier;

    try {
      const confirmationResult = await signInWithPhoneNumber(
        auth,
        phoneNumber,
        appVerifier
      );
      setVerificationId(confirmationResult.verificationId);
      setMessage("OTP sent successfully. Please check your phone.");
      setError("");
    } catch (error: any) {
      setError(error.message);
      setMessage("");
    }
  };

  const handleVerifyOtp = async () => {
    if (!verificationId) return;

    try {
      const credential = PhoneAuthProvider.credential(verificationId, otp);
      await signInWithCredential(auth, credential);
      setMessage("Phone number verified successfully.");
      setError("");
    } catch (error: any) {
      setError(error.message);
      setMessage("");
    }
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Sign In
      </Typography>
      {error && <Typography color="error">{error}</Typography>}
      {message && <Typography color="primary">{message}</Typography>}
      {!verificationId ? (
        <>
          <TextField
            label="Phone Number"
            value={phoneNumber}
            onChange={(e) =>
              setPhoneNumber(
                e.target.value.startsWith("+1")
                  ? e.target.value
                  : "+1" + e.target.value.replace(/^\+1/, "")
              )
            }
            fullWidth
            margin="normal"
          />
          <Button variant="contained" color="primary" onClick={handleSendOtp}>
            Send OTP
          </Button>
          <div id="recaptcha-container"></div>
        </>
      ) : (
        <>
          <TextField
            label="OTP"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            fullWidth
            margin="normal"
          />
          <Button variant="contained" color="primary" onClick={handleVerifyOtp}>
            Verify OTP
          </Button>
        </>
      )}
    </Container>
  );
};

export default SignIn;

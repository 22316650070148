import React from "react";
import { Box, Container, Grid, Link, Typography } from "@mui/material";

interface FooterProps {
  setModalOpen: (type: string) => void;
}

const Footer: React.FC<FooterProps> = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#046E00",
        color: "white",
        padding: "20px 0",
        mt: 4,
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} sm={8} sx={{ order: { xs: 1, sm: 2 } }}>
            <Grid
              container
              spacing={1}
              justifyContent={{ xs: "center", sm: "flex-start" }}
            >
              <Grid item>
                <Link
                  href="/acceptable-use.html"
                  color="inherit"
                  underline="hover"
                >
                  Acceptable Use
                </Link>
              </Grid>
              <Grid item>
                <Link href="/contact.html" color="inherit" underline="hover">
                  Contact Us
                </Link>
              </Grid>
              <Grid item>
                <Link
                  href="/delete-my-data.html"
                  color="inherit"
                  underline="hover"
                >
                  Delete My Data
                </Link>
              </Grid>
              <Grid item>
                <Link href="/disclaimer.html" color="inherit" underline="hover">
                  Disclaimer
                </Link>
              </Grid>
              <Grid item>
                <Link href="/eula.html" color="inherit" underline="hover">
                  EULA
                </Link>
              </Grid>
              <Grid item>
                <Link
                  href="/privacy-policy.html"
                  color="inherit"
                  underline="hover"
                >
                  Privacy Policy
                </Link>
              </Grid>
              <Grid item>
                <Link
                  href="/terms-of-use.html"
                  color="inherit"
                  underline="hover"
                >
                  Terms of Use
                </Link>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={2} sx={{ order: { xs: 2, sm: 3 } }}>
            <Typography
              variant="body2"
              component="p"
              textAlign={{ xs: "center", sm: "left" }}
            >
              ©2024 Vicinity LLC
            </Typography>
          </Grid>
          <Grid item xs={12} sm={1} sx={{ order: { xs: 3, sm: 1 } }}>
            <img
              src="/flag.png"
              alt="Vicinity Logo"
              style={{
                height: "30px",
                width: "50px",
                marginTop: "-5px",
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;

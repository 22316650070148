import { List, ListItem } from "@mui/material";
import React from "react";

export default function ActivePreventionDialog() {
  return (
    <section>
      <h2 style={{ color: "#046E00", paddingLeft: "15px" }}>
        How vicinity prevents hookups and intentional daters.{" "}
      </h2>
      <ul>
        <li style={{ paddingBottom: "30px" }}>
          Users can report other users that misuse the intention of the Vicinity
          app including intentional dating and hookups.
        </li>

        <li>
          Vicinity uses enhanced detection that seeks out inappropriate
          behavior.
        </li>
      </ul>
    </section>
  );
}
